<template>
  <div class="notfound">
    <section>
      <div class="content">
        <h2>ページが見つかりません</h2>
        <a class="button" href="/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 7.093v-5.093h-3v2.093l3 3zm4 5.907l-12-12-12 12h3v10h7v-5h4v5h7v-10h3zm-5 8h-3v-5h-8v5h-3v-10.26l7-6.912 7 6.99v10.182z"/></svg>ホームに戻る</a>
      </div>
      <div class="footer">
        <p class="copywrite">© 2021 yo3.co.jp All Rights Reserved</p>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: '404',
  data () {
    return {}
  },
  mounted () {
    const title = '404｜株式会社ヨヨヨ'
    const keywords = '淡路島,システム開発,ホームページ制作,EC,通販サイト,株式会社ヨヨヨ'
    const description = 'ページが見つかりません。'
    const robots = 'noindex,nofollow'

    document.title = title;
    document.querySelector("meta[name='keywords']").setAttribute('content', keywords)
    document.querySelector("meta[name='description']").setAttribute('content', description)
    document.querySelector("meta[name='robots']").setAttribute('content', robots)
    document.querySelector("meta[property='og:title']").setAttribute('content', title)
    document.querySelector("meta[property='og:description']").setAttribute('content', description)
  },
  created () {
  }}
</script>


<style scoped>
section .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: auto;
    height: 250px;
}
</style>
